@font-face {
  font-family: ACaslonPro-Regular;
  src: url('./fonts/ACaslonPro-Regular.otf');

}
@font-face {
  font-family: ProximaNova-Regular;
  src: url('./fonts/ProximaNova-Regular.otf')

}
@font-face {
  font-family: ProximaNova-Semibold;
  src: url('./fonts/ProximaNova-Semibold.otf')

}
.cairo-font{
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings:
    "slnt" 0;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 1s infinite;
}













.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
