@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply block text-[2em] font-bold;
  }
  h2 {
    @apply text-[1em] block font-bold;
  }
  h3 {
    @apply text-[12px] block font-bold;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  font-family: "ProximaNova-Regular";
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.trial {
  background-image: url("https://my.matterport.com/show/?m=B7TmfHnR5np&brand=0");
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* height: 90vh !important;
  overflow: hidden !important; */
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.images {
  object-fit: contain !important;
}
/* .mySwiper {
  height: 90vh !important;

  overflow: hidden !important;
}
.images {
  object-fit: contain !important;
}
@media only screen and (max-width: 1350px) {
 .mySwiper {
  height: auto !important;
}
.swiper-slide {
height: auto !important;
}
} */
 